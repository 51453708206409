<template>
<div class="v-drawer">
  
  <transition name="fade"
    @before-enter="$emit('enter')">
    <div class="v-drawer__underlay"
      v-if="modelValue"
      @click="close()">
    </div>
  </transition>

  <transition name="slide">

    <div class="v-drawer__content"
      v-if="modelValue">
      
      <div class="v-drawer__header">
        <div>
          <div class="v-drawer__title"
            v-if="title">
            {{ title }}
          </div>
          <div class="v-drawer__subtitle"
            v-if="subtitle">
            {{ subtitle }}
          </div>
        </div>
        <i class="material-symbols v-drawer__icon-close"
          @click="close()">
          close
        </i>
      </div>

      <div class="v-drawer__slot">
        <slot/>
      </div>

      <div>
        <div class="v-drawer__buttons">
          <button class="v-drawer__button"
            :class="{ 'button-primary': !i, 'button-secondary': i, wide: !!action.label }"
            v-for="(action, i) in actions.filter(e => e.visible ?? true)"
            :disabled="action.disabled"
            :style="action.style"
            @click="action.action">
            <span class="spinner"
              v-if="isLoading">
            </span>
            <i class="material-symbols-outlined"
              v-if="action.icon">
              {{ action.icon }}
            </i>
            <span v-if="action.label">
              {{ action.label }}
            </span>
          </button>
        </div>
        <div class="text-error"
          v-if="hasError">
          Будь ласка, виправте усі помилки
        </div>
      </div>

    </div>

  </transition>

</div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    hasError: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    width: { type: String, required: false, default: 'fit-content' },
    duration: { type: String, required: false, default: '150ms' },
    actions: { type: Array, required: false, default: [] },
  },
  unmounted() {
    document.body.style.overflowY = 'auto'
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    isDropdownVisible() {
      return this.actions.slice(1).some(e => e?.visible ?? true)
    },
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false)
      this.$emit('close')
    },
    async submit() {
      this.isLoading = true
      await this.actions[0].action()
      this.isLoading = false
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        document.body.style.overflowY = val ? 'hidden' : 'auto'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v-drawer {
  position: absolute;
  z-index: 999;
  &__underlay {
    content: '';
    position: fixed;
    top: 0; left: 0;
    right: 0; bottom: 0;
    background-color: #0002;
  }
  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: fixed;
    top: 4px;
    right: 4px;
    padding: 2em min(5vw, 1.75em);
    width: min(v-bind(width), calc(100% - 8px));
    height: calc(100% - 8px);
    background: #fff;
    border-radius: .7em;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
  }
  &__title {
    font-size: 1.5em;
    font-weight: bold;
  }
  &__subtitle {
    margin-top: .25em;
    color: #000c;
  }
  &__icon-close {
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
  }
  &__slot {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2em;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 1em;
    width: 100%;
    background-color: #fff;
  }
  &__button {
    &.wide { flex-grow: 1; }
    i { font-size: 1.5em; }
    span::first-letter { text-transform: uppercase; }
  }
}

.slide {
  &-enter-active,
  &-leave-active {
    transition: transform v-bind(duration) ease-out;
  }
  &-enter-from,
  &-leave-to {
    transform: translate(100%, 0%);
  }
}
</style>