<template>
<teleport
  :to="parent"
  v-if="modelValue">

  <Transition
    name="fade"
    appear>

    <div class="v-modal__underlay"
      @click="close()">

      <Transition
        name="pop"
        appear>

        <div class="v-modal" 
          :style="styleModal"
          @click.stop="_ => {}">

          <div class="material-symbols v-modal__cross" 
            @click="close()">
            close
          </div>

          <div class="v-modal__content">
            <div class="v-modal__title"
              v-if="title" 
              style="text-align:center">
              {{ title }}
            </div>
            <slot name="content"/>
          </div>
          <div class="v-modal__buttons"
            v-if="!!$slots.buttons">
            <slot name="buttons"/>
          </div>

        </div>

      </Transition>

    </div>

  </Transition>

</teleport>
</template>

<script>
import './v-modal-styles.scss'
import VDropdown from '@/components/ui/v-dropdown.vue'
export default {
  emits: [
    "update:modelValue",
    'close',
  ],
  components: {
    VDropdown
  },
  props: {
    parent: { type: String, required: false, default: 'body' },
    modelValue: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    width: { type: String, required: false, default: "21em" },
    style: { type: Object, required: false, default: {} },
  },
  data() {
    return {
      modalCount: 0,
      styleModal: {}
    }
  },
  mounted() {
    this.modalCount = document.querySelectorAll('.modal-underlay').length
    this.styleModal = {
      width: this.width,
      zIndex: this.modalCount + 5,
      ...this.style
    }
  },
  unmounted() {
    document.body.style.overflowY = 'auto'
  },
  methods: {
    close() {
      this.$emit("close")
      this.$emit("update:modelValue", false)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        document.body.style.overflowY = val ? 'hidden' : 'auto'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
:deep(ol), :deep(ul) {
  padding-left: 1.5em;
  &:not(:first-child) { margin-top: 1.25em; }
  li { padding-left: .5em !important; }
  li::before, li::marker {
    margin-left: .5em;
    margin-right: .5em;
    font-weight: bold;
  }
}
:deep(p) {
  margin: 0;
  line-height: 1.5;
  &:empty { display: none; }
  &:not(:last-child) { margin-bottom: .5em; }
}
:deep(h1) {
  margin-bottom: .75em;
  font-size: 2em;
  font-weight: 600;
  &:not(:first-child) { margin-top: 1.5em; }
  &:not(:last-child) { margin-bottom: 1.5em; }
}
:deep(h2) {
  margin-bottom: .75em;
  font-size: 1.5em;
  font-weight: 600;
  &:not(:first-child) { margin-top: 1.5em; }
  &:not(:last-child) { margin-bottom: 1.5em; }
}

.v-modal {
  position: relative;
  margin: auto;
  border-radius: 1em;
  background: white;
  box-shadow: 0 2px 18px #0003;
  font-family: 'Gilroy';
  scroll-snap-align: center;
  color: initial;
  &__underlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: safe center;
    z-index: 999;
    left: 0; top: 0;
    right: 0; bottom: 0;
    padding: 2em 2vw;
    background: #0005;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__cross {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%,-30%);
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px #0003;
    font-size: 2em !important;
    background: #fff;
    text-align: center;
  }
  &__title {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.5;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1.75em;
    padding: 0 min(5vw, 1.75em);
    margin: 1.75em 0;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: stretch;
    column-gap: 1em;
    margin: 1.75em 0;
    padding: 0 min(4vw, 1.75em);
    & > button { flex-basis: 100%; } 
  }
  &__icon {
    margin-bottom: -1rem;
    text-align: center;
    font-size: 7.5em !important;
    font-weight: 400;
  }
  &__text {
    line-height: 1.5;
    color: gray;
  }
  &__separator {
    display: flex;
    gap: .5em;
    font-size: .9em;
    color: #0004;
    &::before, &::after{
      content: "";
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
    }
  }
  &__delimiter {
    margin: 1.5em -1.75em;
    height: .7em;
    background-color: #00000010;
    border-top: 1px solid #00000015;
  }
}
</style>