<template>
<div class="loader"
  :style="{ background: bgColor }">
  <div class="loader__ripple">
    <div></div>
    <div></div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    bgColor: { type: String, required: false, default: '#0003' }
  }
}
</script>

<style scoped>
.loader {
  z-index: 9999;
  position: fixed;
  left: 0; top: 0;
  width: min(100vw, 100%);
  height: min(100vh, 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader__ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.loader__ripple div {
  position: absolute;
  border: 5px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader__ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 96px;
    height: 96px;
    opacity: 0;
  }
}
</style>